@tailwind base;
@tailwind components;
@tailwind utilities;
html { scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page p, #page h2, #page ul {
  margin-top: 20px;
}

#page ul {
  list-style: circle;
  padding-left: 30px;
}
#page ul li {
  padding-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

#page a {
  color: violet;
}